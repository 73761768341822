import { styled } from '@mui/material/styles';
import { Button, Card, CardActions, CardContent, FormControlLabel, Grid, Slide, Switch, Typography } from "@mui/material";
import { CheckSharp } from "@mui/icons-material";
import React, { ChangeEvent, forwardRef, Ref, useEffect, useImperativeHandle, useState } from "react";
import { useCookies } from "react-cookie";
import { gtag, setGtagEnabled } from "../../utils/gtag";
import { useLocation } from "react-router-dom";

const PREFIX = 'Analytics';

const classes = {
    consent: `${PREFIX}-consent`,
    section: `${PREFIX}-section`,
    checkmark: `${PREFIX}-checkmark`,
    underline: `${PREFIX}-underline`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`&.${classes.consent}`]: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        padding: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        maxWidth: '600px',
    },

    [`& .${classes.section}`]: {
        paddingTop: theme.spacing(1),
    },

    [`& .${classes.checkmark}`]: {
        color: theme.palette.common.white,
    },

    [`& .${classes.underline}`]: {
        textDecoration: 'underline',
    }
}));

const CONSENT_COOKIE = 'consent';

export const Analytics = forwardRef((props, ref: Ref<{ show: () => void }>) => {

    const [cookies, setCookie, removeCookie] = useCookies([CONSENT_COOKIE]);
    const consentCookie = cookies.consent as string | undefined;
    const [optional, setOptional] = useState(consentCookie !== undefined && consentCookie === 'true');
    const [dirty, setDirty] = useState(consentCookie !== undefined);
    const [show, setShow] = useState(false);
    const location = useLocation();
    useImperativeHandle(ref, () => ({ show: () => setShow(true) }));

    useEffect(() => {
        setShow(consentCookie === undefined);
    }, [consentCookie]);

    useEffect(() => {
        if (optional) {
            setGtagEnabled(optional);
        }
    }, [optional]);

    useEffect(() => {
        if (optional) {
            gtag('set', ['page', location.pathname + location.search]);
            gtag('send', ['pageview']);
        }
    }, [location, optional]);

    const change = (event: ChangeEvent) => {
        setOptional((event.target as HTMLInputElement).checked);
        setDirty(true);
    };

    const acceptAll = () => {
        setOptional(true);
        save(true);
    };

    const reject = () => {
        setOptional(false);
        save(false);
    }

    const close = () => {
        setShow(false);
    }

    const save = (optional: boolean) => {
        setCookie(CONSENT_COOKIE, optional, { path: '/', maxAge: 5256006 });
        setShow(false);

        if (optional) {
            setGtagEnabled(true);
        }
    };

    return (
        <Root className={classes.consent}>
            <Slide direction="up" in={show} mountOnEnter unmountOnExit>
                <Card raised={true}>
                    <CardContent>
                        <Typography variant="body2">
                            Az oldal sütiket használ a weboldal működéséhez és statisztikák gyűjtéséhez. Az Elfogadom gombra
                            kattintással hozzájárul mindezekhez. A részletekről az
                            <React.Fragment /> <a className={classes.underline} href="/legal/adatkezeles.pdf">Adatkezelési
                                Tájékoztató</a>ban tájékozódhat.
                        </Typography>
                        <div className={classes.section}>
                            <FormControlLabel
                                control={<Switch checked={true} name="required" required color="primary" />}
                                disabled={true}
                                label={<React.Fragment>
                                    <Typography variant="body1">
                                        Az oldal működéséhez elengedhetetlen cookie-k
                                    </Typography>
                                    <Typography variant="body2">
                                        Ezeket a cookie-kat a bankkártyás csalások megelőzéséhez használja egy harmadik fél.
                                    </Typography>
                                </React.Fragment>}
                            />
                        </div>
                        <div className={classes.section}>
                            <FormControlLabel
                                control={<Switch checked={optional} onChange={change} color="primary" />}
                                label={<React.Fragment>
                                    <Typography variant="body1">
                                        Statisztikai célú cookie-k
                                    </Typography>
                                    <Typography variant="body2">
                                        Ezeket az adatok azért gyűjtjük, hogy aggregált statisztikákat készítsünk és azok alapján
                                        fejlesszük weboldalunkat.
                                    </Typography>
                                </React.Fragment>}
                            />
                        </div>
                    </CardContent>
                    <CardActions>
                        <Grid container justifyContent="space-between" spacing={3}>
                            {dirty ?
                                <React.Fragment>
                                    <Grid item>
                                        <Button onClick={close}>
                                            Bezárás
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button color="primary" variant="contained" onClick={() => save(optional)}>
                                            Mentés
                                        </Button>
                                    </Grid>
                                </React.Fragment> :
                                <React.Fragment>
                                    <Grid item>
                                        <Button onClick={reject}>
                                            Elutasítás
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button color="primary" variant="contained"
                                            startIcon={<CheckSharp className={classes.checkmark} />}
                                            onClick={acceptAll}>
                                            Elfogadás
                                        </Button>
                                    </Grid>
                                </React.Fragment>}
                        </Grid>
                    </CardActions>
                </Card>
            </Slide>
        </Root>
    );
}
)
    ;
