const tag = 'G-CB3M9E53JX';
const url = `https://www.googletagmanager.com/gtag/js?id=${tag}`;

declare global {
    interface Window {
        dataLayer: Array<any> | undefined
    }
}

export function setGtagEnabled(enabled: boolean) {
    if (enabled) {
        if (!window.dataLayer) {
            init();
        }
    } else {
        window.dataLayer = undefined;
    }
}

export function gtag<T>(key: string, props: any[]) {
    if (window.dataLayer) {
        window.dataLayer.push([key, ...props]);
    }
}

function init() {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.body.append(script);

    window.dataLayer = window.dataLayer || [];

    gtag('js', [new Date()]);
    gtag('config', [tag]);
}
