import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {EventOrder} from "@praebitor/client";
import {numberFormat} from "../../utils/format";
import {aN} from "../../utils/assert";
import React from "react";


export function OrderSummary({order}: { order: EventOrder }) {
    return <TableContainer component={Paper}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Név</TableCell>
                    <TableCell>Egységár</TableCell>
                    <TableCell>Darabszám</TableCell>
                    <TableCell>Részösszeg</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    order.options.map(option =>
                        <TableRow key={option.option.id}>
                            <TableCell>{option.option.name.split('|')[0]}</TableCell>
                            <TableCell>{numberFormat(aN(option.option.price))} Ft</TableCell>
                            <TableCell>{option.quantity} db</TableCell>
                            <TableCell>{numberFormat(aN(option.subtotal))} Ft</TableCell>
                        </TableRow>
                    )
                }
                <TableRow>
                    <TableCell colSpan={3}>Összesen</TableCell>
                    <TableCell>{numberFormat(aN(order.total))} Ft</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer>;
}
