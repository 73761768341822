import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { OrderSummaryRestModel, PaymentStatus } from '@praebitor/client';
import { useLocation } from 'react-router-dom';
import { CircularProgress, Container, Typography } from "@mui/material";
import { ApiContext } from "../Api";
import { Border } from "../fragments/Border";

const PREFIX = 'Summary';

const classes = {
    root: `${PREFIX}-root`,
    center: `${PREFIX}-center`,
    code: `${PREFIX}-code`
};

const StyledContainer = styled(Container)((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {
        marginTop: '1rem'
    },

    [`& .${classes.center}`]: {
        textAlign: 'center'
    },

    [`& .${classes.code}`]: {
        fontFamily: '"Roboto Mono", monospace',
        fontSize: theme.typography.h2.fontSize,
    }
}));

function isFinished(status: PaymentStatus) {
    const values = [
        'Succeeded',
        'Canceled',
        'Failed',
        'PartiallySucceeded',
        'Expired'
    ];

    // @todo
    return values.includes(status as any);
}

function OrderSummary({ orderSummary }: { orderSummary: OrderSummaryRestModel }) {

    // @todo
    const code = orderSummary.order.barionPayments[0].status as any as string;

    if (code === 'Succeeded') {
        return <div className={classes.center}>
            <Typography variant="h4">Köszönjük szépen a rendelését!</Typography>
            <Typography variant="body1">
                Kérjük az alábbi kódot, vagy az email visszaigazolásban található QR code-ot,
                az előadás <strong>kezdete előtt</strong> legyen szíves bemutatni.
            </Typography>
            <Typography className={classes.code}>
                {orderSummary.order.code}
            </Typography>
        </div>;
    }

    if (code === 'Canceled') {
        return <div className={classes.center}>A fizetés nem történt meg. Kérjük próbálkozzon újra, vagy vegye fel a kapcsolatot munkatársainkkal!</div>;
    }

    if (code === 'Expired') {
        return <div className={classes.center}>A fizetési határidő lejárt. Kérjük próbálkozzon újra!</div>;
    }

    return <div className={classes.center}>Ismeretlen hiba a fizetésnél: {code}</div>;
}

export default function Summary() {

    const api = useContext(ApiContext);
    const location = useLocation();
    const [orderSummary, setOrderSummary] = useState<OrderSummaryRestModel>();
    const timer = useRef(0);

    const fetchState = useCallback(() => {
        const paymentId = new URLSearchParams(location.search).get('paymentId');
        if (paymentId == null) {
            throw new Error('missing paymentId');
        }
        api.getOrder(paymentId).then(result => {
            setOrderSummary(result);
            if (isFinished(result.order.barionPayments[0].status)) return;
            timer.current = window.setTimeout(() => fetchState(), 5000);
        });
    }, [location, api]);

    useEffect(() => {
        timer.current = window.setTimeout(fetchState);
        return () => {
            if (timer.current) clearTimeout(timer.current);
        };
    }, [location, timer, fetchState]);

    return (
        <StyledContainer className={classes.root}>
            {(orderSummary && isFinished(orderSummary.order.barionPayments[0].status)) ? (
                <Border>
                    <OrderSummary orderSummary={orderSummary} />
                </Border>
            ) : (
                <div className={classes.center}>
                    <CircularProgress /><br />
                    Feldolgozás folyamatban... {orderSummary && '(' + orderSummary.order.barionPayments[0].status + ')'}<br />
                </div>
            )}
        </StyledContainer>
    );
}
