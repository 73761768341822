import React, { useContext, useEffect, useState } from "react";
import { AreaWithInfoRestModel } from '@praebitor/client';
import { ApiContext } from "../../Api";
import { aN } from "../../../utils/assert";
import { Link, Route, Routes, useParams } from "react-router-dom";
import OrderStep from "./OrderStep";
import { Divider, List, ListItemAvatar, ListItemButton, ListItemText, Typography } from "@mui/material";
import { Room as RoomIcon } from '@mui/icons-material';

function capacity(area: AreaWithInfoRestModel) {
    if (aN(area.area.capacityOrderOption) >= 0) {
        return area.area.capacityOrderOption;
    }
    return area.area.capacityOrder;
}

function remainingOrders(area: AreaWithInfoRestModel): number {
    if (aN(area.area.capacityOrderOption) >= 0) {
        return aN(area.area.capacityOrderOption) - aN(area.occupiedOptions);
    }
    return aN(area.area.capacityOrder) - aN(area.occupiedOrders);
}

function ConditionalLink({ children, disabled, to }: { children: React.ReactNode, disabled: boolean, to: string }) {
    if (disabled) {
        return <React.Fragment>
            {children}
        </React.Fragment>;
    }
    return <Link to={to}>
        {children}
    </Link>;
}

export function Areas() {
    const api = useContext(ApiContext);
    const [areas, setAreas] = useState([] as Array<AreaWithInfoRestModel>);
    const { eventId } = useParams<"eventId">();

    useEffect(() => {
        api.areas(~~aN(eventId)).then(result => {
            setAreas(result);
        });
    }, [eventId, api]);

    return (<Routes>
        <Route
            path="/:areaId/*"
            element={<OrderStep areas={areas} />}
        />
        <Route
            path="/"
            element={<React.Fragment>
                <Typography variant={"body1"}>
                    Kérem válassza ki a helyszínt melyre előrendelni kíván!
                </Typography>
                <List>
                    {areas.map(area => (
                        <React.Fragment key={area.area.id}>
                            <ConditionalLink to={"" + area.area.id} disabled={remainingOrders(area) < 1}>
                                <ListItemButton
                                    disabled={remainingOrders(area) < 1}>
                                    <ListItemAvatar sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <RoomIcon />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={area.area.name}
                                        secondary={'Szabad: ' + remainingOrders(area) + ', Összesen: ' + capacity(area)}
                                    />
                                </ListItemButton>
                            </ConditionalLink>
                            <Divider variant="inset" component="li" />
                        </React.Fragment>
                    ))}
                </List>
            </React.Fragment>} />
    </Routes>);
}


