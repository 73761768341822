import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import { BusinessClient, Client, EventOrder } from '@praebitor/client';
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";
import { OrderSummary } from "../../fragments/OrderSummary";
import { isAnyClient, isBusinessClient } from "../../../utils/convert";

const PREFIX = 'Preview';

const classes = {
    padding: `${PREFIX}-padding`,
    center: `${PREFIX}-center`,
    barion: `${PREFIX}-barion`,
    asterisk: `${PREFIX}-asterisk`,
    underlined: `${PREFIX}-underlined`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.padding}`]: {
        paddingTop: '1rem'
    },

    [`& .${classes.center}`]: {
        textAlign: 'center'
    },

    [`& .${classes.barion}`]: {
        width: '100%'
    },

    [`& .${classes.asterisk}`]: {
        color: 'red'
    },

    [`& .${classes.underlined}`]: {
        textDecoration: 'underline'
    }
}));

const ClientInfo = ({ client }: { client: Client }) => {
    if (!isAnyClient(client)) {
        throw new Error("unknown client type: " + (client as Client).type);
    }

    return (
        <React.Fragment>
            <Grid container justifyContent="center" className={classes.padding}>
                <Grid item>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Név</TableCell>
                                            <TableCell>{client.name}</TableCell>
                                        </TableRow>
                                        {isBusinessClient(client) &&
                                            <TableRow>
                                                <TableCell>Adószám</TableCell>
                                                <TableCell>{client.taxCode}</TableCell>
                                            </TableRow>
                                        }
                                        <TableRow>
                                            <TableCell>E-Mail cím</TableCell>
                                            <TableCell>{client.email}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Telefonszám</TableCell>
                                            <TableCell>{client.phone}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Irányítószám</TableCell>
                                            <TableCell>{client.address.areaCode}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Város</TableCell>
                                            <TableCell>{client.address.city}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Cím</TableCell>
                                            <TableCell>{client.address.address}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export function Preview({ order, onSubmit }: { order: EventOrder, onSubmit: () => void }) {
    const [agreed, setAgreed] = useState(false);


    return order.options ? (
        <Root>
            <OrderSummary order={order} />
            <ClientInfo client={order.client} />
            <Grid container justifyContent="center" className={classes.padding}>
                <Grid item>
                    <FormControlLabel
                        control={<Checkbox color="primary" checked={agreed} onChange={x => setAgreed(x.target.checked)}
                            required />}
                        label={<span>Elfogadom az <a target="_blank" href="/legal/aszf.pdf" className={classes.underlined}>általános szerződési feltételeket</a>&nbsp;
                            <span className={classes.asterisk}>*</span></span>}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <Grid item>
                    <img src="./img/barion.svg" alt="barion" className={classes.barion} />
                </Grid>
            </Grid>
            <Grid container justifyContent="center" className={classes.padding}>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={!agreed}
                        onClick={onSubmit}>
                        Fizetés
                    </Button>
                </Grid>
            </Grid>
        </Root>
    ) : (
        <div className={classes.center}>
            <CircularProgress />
        </div>
    );
}
