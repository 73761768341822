import React, {HTMLAttributes} from "react";
import { styled } from '@mui/material/styles';
import {Container, Paper} from "@mui/material";

const PREFIX = 'Border';

const classes = {
    pane: `${PREFIX}-pane`,
    innerContainer: `${PREFIX}-innerContainer`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.pane}`]: {
        paddingBottom: theme.spacing(4),
    },

    [`& .${classes.innerContainer}`]: {
        background: 'rgba(255, 255, 255, 0.4)',
        padding: theme.spacing(2),
        backdropFilter: 'blur(10px)',
    }
}));

export function Border({children, ...props}: { children: React.ReactNode } & HTMLAttributes<any>) {


    return (
        <Root>
            <Container maxWidth="md" {...props}>
                <Paper className={classes.innerContainer}>
                    {children}
                </Paper>
            </Container>
        </Root>
    );
}
