import React, {useContext, useEffect, useState} from 'react';
import { styled } from '@mui/material/styles';
import {EventOrder} from '@praebitor/client';
import {useParams} from 'react-router-dom';
import {CircularProgress, Container, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import {ApiContext} from "../Api";
import {DateFormat} from "../utils/DateFormat";
import {TimeFormat} from "../utils/TimeFormat";
import PaymentIndicator from "../utils/PaymentIndicator";
import {OrderSummary} from "../fragments/OrderSummary";

const PREFIX = 'Code';

const classes = {
    root: `${PREFIX}-root`,
    code: `${PREFIX}-code`,
    flex: `${PREFIX}-flex`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        paddingTop: theme.spacing(2),
    },

    [`& .${classes.code}`]: {
        fontFamily: "monospace"
    },

    [`& .${classes.flex}`]: {
        flex: 1,
        paddingLeft: theme.spacing(1)
    }
}));

export default function Code() {

    const api = useContext(ApiContext);
    const {code} = useParams<"code">();
    const [order, setOrder] = useState<EventOrder>();

    useEffect(() => {
        if (code == null) {
            return;
        }
        api.getEventOrderByCode(code).then(result => {
            setOrder(result);
        });
    }, [code, api]);

    return order ? (
            <Container className={classes.root} maxWidth="md">
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    Megrendelő neve
                                </TableCell>
                                <TableCell>
                                    <strong>
                                        {order.client.name}
                                    </strong>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    Fizetési állapot
                                </TableCell>
                                <TableCell>
                                    {order.barionPayments.map(payment => (
                                        <PaymentIndicator status={payment.status}/>
                                    ))}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    Esemény
                                </TableCell>

                                <TableCell>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <DateFormat date={order.event.date}/>
                                        </Grid>
                                        <Grid item className={classes.flex}>
                                            <Root>{order.event.name}</Root>
                                            <TimeFormat date={order.event.date}/>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    Helyszín
                                </TableCell>

                                <TableCell>
                                    {order.area.name}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    Rendelés azonosító
                                </TableCell>
                                <TableCell>
                                    {order.id}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    Rendelés kódja
                                </TableCell>
                                <TableCell>
                                    <div className={classes.code}>
                                        {order.code}
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <div>&nbsp;</div>
                <OrderSummary order={order}/>
            </Container>
        ) :
        <div>
            <CircularProgress/>
        </div>;
}
