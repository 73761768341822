
export enum FundingSource {
ALL,
BALANCE
}

export enum PaymentStatus {
Prepared,
Started,
InProgress,
Waiting,
Reserved,
Authorized,
Canceled,
Succeeded,
Failed,
PartiallySucceeded,
Expired
}

export enum PaymentType {
IMMEDIATE,
RESERVATION,
DELAYED_CAPTURE
}

