import React, { Ref, useContext, useState } from "react";
import { styled } from '@mui/material/styles';
import { Address, BusinessClient, Client, OrderDetailsRestModel, PersonClient } from '@praebitor/client';
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, Paper, TextField, Typography } from "@mui/material";
// @ts-ignore
import { IMaskMixin } from 'react-imask';
import { ApiContext } from "../../Api";
import { TextFieldProps } from "@mui/material/TextField/TextField";

const PaddedGrid = styled(Grid)(({ theme }) => ({
    paddingTop: '1rem'
}));

const getInitial = (key: string, defValue: string = '{}') => {
    if (!window['localStorage']) return {};
    try {
        console.log('initial', key, localStorage.getItem(key));
        return JSON.parse(localStorage.getItem(key) || defValue);
    } catch (e) {
        return {};
    }
}

const setInitial = (key: string, value: any) => {
    if (!window['localStorage']) return {};
    return localStorage.setItem(key, JSON.stringify(value));
}

const translations = {
    'must not be empty': 'A mező kitöltése kötelező!',
    'must be a well-formed email address': 'Az e-mail cím helytelen formátumú!',
} as Record<string, string>;

const MaskedInput = IMaskMixin(({ inputRef, ...props }: { inputRef: Ref<HTMLInputElement> } & TextFieldProps) =>
    <TextField
        fullWidth
        required
        inputRef={inputRef}
        {...props}
    />);

export function Details({ onSubmit }: { onSubmit: (model: OrderDetailsRestModel) => void }) {
    const api = useContext(ApiContext);
    const [client, setClient] = useState(getInitial('client') as Client);
    const [address, setAddress] = useState(getInitial('address') as Address);
    const [taxCode, setTaxCode] = useState(getInitial('taxCode', '""') as string);
    const [showTaxCode, setShowTaxCode] = useState(!!taxCode);
    const [errors, setErrors] = useState({} as any);

    const trySubmit = (client: Client): any => {
        const model: OrderDetailsRestModel = (taxCode && showTaxCode) ? {
            client: { ...client, address: address, type: 'business', taxCode } as BusinessClient
        } : {
            client: { ...client, address: address, type: 'person' } as PersonClient
        };
        api.validateOrder(model).then(result => {
            setInitial('client', result.client);
            setInitial('address', result.client.address);
            setInitial('taxCode', showTaxCode ? taxCode : '');
            onSubmit(result);
        }, (result: Response) => {
            result.json().then(result => {
                setErrors((result.errors as Array<any>).reduce((acc, x) => ({ ...acc, [x.field]: x }), {}))
            });
        });
    }

    const hasError = (field: string): boolean => {
        return field in errors;
    }

    const errorMessage = (field: string): string => {
        if (!(field in errors)) {
            return '';
        }
        return translations[errors[field].defaultMessage] || errors[field].defaultMessage;
    }

    return (
        <Paper elevation={3} sx={theme => ({ padding: theme.spacing(3) })}>
            <form>
                <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
                    Kérem az alábbi mezőkben adja meg adatait!
                </Typography>
                <PaddedGrid container spacing={3}>
                    <Grid item sm xs={12}>
                        <TextField
                            label="Név"
                            fullWidth
                            required
                            value={client.name}
                            onChange={e => setClient({ ...client, name: e.target.value })}
                            error={hasError('client.name')}
                            helperText={errorMessage('client.name')}
                        />
                    </Grid>
                    <Grid item sm xs={12}>
                        <TextField
                            label="E-Mail cím"
                            fullWidth
                            required
                            value={client.email}
                            onChange={e => setClient({ ...client, email: e.target.value })}
                            error={hasError('client.email')}
                            helperText={errorMessage('client.email')}
                        />
                    </Grid>
                    <Grid item sm xs={12}>
                        <TextField
                            label="Telefonszám"
                            fullWidth
                            required
                            value={client.phone}
                            onChange={e => setClient({ ...client, phone: e.target.value })}
                            error={hasError('client.phone')}
                            helperText={errorMessage('client.phone')}
                        />
                    </Grid>
                </PaddedGrid>
                <PaddedGrid container spacing={3}>
                    <Grid item sm={3} xs={12}>
                        <MaskedInput
                            label="Irányítószám"
                            mask="0000"
                            value={address.areaCode}
                            onAccept={(value: string) => setAddress({
                                ...address,
                                areaCode: value
                            })}
                            required
                            error={hasError('client.address.areaCode')}
                            helperText={errorMessage('client.address.areaCode')}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <TextField
                            label="Város"
                            fullWidth
                            required
                            value={address.city}
                            onChange={e => setAddress({
                                ...address, city: e.target.value
                            })}
                            error={hasError('client.address.city')}
                            helperText={errorMessage('client.address.city')}
                        />
                    </Grid>
                    <Grid item sm={5} xs={12}>
                        <TextField
                            label="Cím"
                            fullWidth
                            required
                            value={address.address}
                            onChange={e => setAddress({
                                ...address, address: e.target.value
                            })}
                            error={hasError('client.address.address')}
                            helperText={errorMessage('client.address.address')}
                        />
                    </Grid>
                </PaddedGrid>
                <PaddedGrid container alignItems="center">
                    <Grid item sm={6} xs={12}>
                        <FormGroup>
                            <FormControlLabel label="Adószám megadása" control={
                                <Checkbox checked={showTaxCode} onChange={e => setShowTaxCode(e.target.checked)} />
                            } />
                        </FormGroup>
                    </Grid>
                    <Grid item sm={6} xs={12} sx={{ display: showTaxCode ? 'flex' : 'none' }}>
                        <TextField
                            label="Adószám"
                            fullWidth
                            value={taxCode}
                            onChange={e => setTaxCode(e.target.value)}
                            error={hasError('client.taxCode')}
                            helperText={errorMessage('client.taxCode')}
                        />
                    </Grid>
                </PaddedGrid>
                <PaddedGrid container justifyContent="center">
                    <Grid item>
                        <Button color="primary" variant="contained" onClick={() => trySubmit(client)}>
                            Rendelés Áttekintése ▶
                        </Button>
                    </Grid>
                </PaddedGrid>
            </form>
        </Paper>
    );
}
