import React from "react";
import { Grid } from "@mui/material";
import { dateFormatter } from "../../utils/format";

export function DateFormat({ date }: { date: string }) {

    const getPart = dateFormatter(date, { month: "short", day: "2-digit" });

    return (
        <Grid container
            alignItems="center"
            justifyContent="center"
            direction="column"
            sx={theme => ({
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.common.white,
                width: '2.5rem',
                height: '2.5rem',
                fontVariant: 'small-caps',
                borderRadius: '3px',
                lineHeight: 1.05
            })
            }>
            <Grid item>
                {getPart('month').replace(/\.$/, '')}
            </Grid>
            <Grid item>
                {getPart('day')}
            </Grid>
        </Grid >
    );
}
