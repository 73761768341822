import React from "react";
import { styled } from '@mui/material/styles';
import { ApiController } from '@praebitor/client';
import { Container, useTheme } from "@mui/material";
import { Alert, AlertTitle } from '@mui/material';
import { apiErrorHandler } from "../utils/componentUtils";

const PREFIX = 'ApiContext';

const classes = {
    paddingTop: `${PREFIX}-paddingTop`
};

export const ApiContext = React.createContext<ApiController>(new ApiController((...args) => {
    throw new Error('No error handler registered: ' + args.toString());
}, () => ({})));

const StyledApiContextProvider = styled(ApiContext.Provider)((
    {
        theme
    }
) => ({
    [`& .${classes.paddingTop}`]: {
        paddingTop: theme.spacing(3)
    }
}));

export const Api: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const theme = useTheme();

    const [error, setError] = React.useState('');

    const api = new ApiController(apiErrorHandler(setError), () => ({}));

    return error ? (
        <Container className={classes.paddingTop}>
            <Alert severity="error">
                <AlertTitle>Hiba történt, kérem vegye fel a kapcsolatot munkatársainkkal</AlertTitle>
                {error}
            </Alert>
        </Container>
    ) :
        (
            <StyledApiContextProvider value={api}>
                {children}
            </StyledApiContextProvider>
        );
}
