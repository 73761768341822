import React from "react";
import { styled } from '@mui/material/styles';
import {PaymentStatus} from "@praebitor/client";
import {Check} from "@mui/icons-material";
import {Grid} from "@mui/material";

const PREFIX = 'PaymentIndicator';

const classes = {
    mark: `${PREFIX}-mark`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.mark}`]: {
        color: theme.palette.success.dark,
        paddingRight: theme.spacing(0.5),
    }
}));

export default function PaymentIndicator({status}: { status: PaymentStatus }) {


    return status !== PaymentStatus.Succeeded ?
        (
            <Grid container alignItems="center">
                <Grid item className={classes.mark}>
                    <Check/>
                </Grid>
                <Grid item>
                    Fizetve
                </Grid>
            </Grid>
        ) :
        (<Root>Nincs fizetve ({status})</Root>);
}
