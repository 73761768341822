import {OrderDetailsRestModel, OrderRestModel} from "@praebitor/client";

export const createPaymentRequest = (
    areaId: number,
    eventId: number,
    options: { [optionId: number]: number },
    details: OrderDetailsRestModel) => ({
    event: {id: eventId},
    area: {id: areaId},
    options,
    client: details.client
} as OrderRestModel);
