import React from "react";
import { styled } from '@mui/material/styles';
import {Paper} from "@mui/material";
import {Border} from "../fragments/Border";
const PREFIX = 'Contact';

const classes = {
    container: `${PREFIX}-container`,
    typo: `${PREFIX}-typo`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.container}`]: {
        paddingTop: theme.spacing(3),
    },

    [`& .${classes.typo}`]: {
        padding: theme.spacing(3),
        '& h4': {
            margin: 0,
            padding: 0,
        },
        '& p': {
            margin: 0,
        }
    }
}));

export function Contact() {


    return (
        <Root>
            <Border className={classes.container}>
                <Paper className={classes.typo}>
                    <h4>Broadway Catering Kft.</h4>
                    <p>Székhely: 1132 Budapest Visegrádi u. 50/c.</p>
                    <p>Telephelyek:</p>
                    <ul>
                        <li>1137 Budapest Szent István krt. 14 (Vígszínház büfé)</li>
                        <li>1051 Budapest Váci utca 9. (Pesti Színház büfé)</li>
                    </ul>
                    <p>Adószám: 13146793-2-41</p>
                    <p>Bankszámlaszám: 10101346-64701600-01005004</p>
                    <p>Cégjegyzékszám: Cg. 01- 09-721006</p>
                    <p>Kapcsolattartó neve: Somodi Zoltán</p>
                </Paper>
            </Border>
        </Root>
    );
}
