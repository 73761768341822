import { Card, CardActions, CardContent, CardHeader, CardMedia, Divider, Grid, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import React, { useContext } from "react";
import { AreaWithInfoRestModel, Option } from '@praebitor/client';
import { ApiContext } from "../../Api";
import { aN } from "../../../utils/assert";
import { numberFormat } from "../../../utils/format";
import { PlusOne } from "@mui/icons-material";

const PREFIX = 'Options';

const classes = {
    media: `${PREFIX}-media`,
    submit: `${PREFIX}-submit`,
    divider: `${PREFIX}-divider`,
    selected: `${PREFIX}-selected`,
    notSelected: `${PREFIX}-notSelected`,
    option: `${PREFIX}-option`,
    fontAdjust: `${PREFIX}-fontAdjust`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.media}`]: {
        height: 190,
    },

    [`& .${classes.submit}`]: {
        paddingTop: '1rem'
    },

    [`& .${classes.divider}`]: {
        margin: '1rem'
    },

    [`& .${classes.selected}`]: {
        boxShadow: 'inset 0 0 3px -1px rgba(0,0,0,0.9), 0 0 15px 0 rgb(64,64,255,1), 0 0 5px 1px rgb(64,128,255,1)',
    },

    [`& .${classes.notSelected}`]: {
        boxShadow: 'inset 0 0 0 0 black, 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    },

    [`& .${classes.option}`]: {
        transition: 'box-shadow 0.15s ease-in-out'
    },

    [`& .${classes.fontAdjust}`]: {
        fontSize: '0.8rem',
    }
}));

export function Options({
    area,
    eventId,
    onSelection
}: { area: AreaWithInfoRestModel, eventId: number, onSelection: (options: { [optionId: number]: number; }) => void }) {

    const api = useContext(ApiContext);
    const [options, setOptions] = React.useState([] as Array<Option>);
    const [selection, setSelection] = React.useState({} as { [id: number]: number })

    React.useEffect(() => {
        api.options(area.area.id).then(result => {
            setOptions(result);
        });
    }, [api, area]);

    function clickHandler(id: number, delta: number) {
        setSelection({ ...selection, [id]: (~~selection[id]) + delta })
    }

    function optionsNumber() {
        return Object.values(selection).reduce((x, y) => x + y, 0);
    }

    function hasOptionCapacity() {
        return aN(area.area.capacityOrderOption) >= 0;
    }

    function remainingOptions(): number {
        if (aN(area.area.capacityOrderOption) < 0) {
            return aN(area.area.capacityOrder) - area.occupiedOrders;
        }
        return aN(area.area.capacityOrderOption) - area.occupiedOptions - optionsNumber();
    }

    function optionName(option: Option): string {
        return option.name.split('|')[0];
    }

    function optionDesc(option: Option): string {
        return option.name.split('|')[1];
    }

    return (
        <Root>
            <Typography>
                Kérjük válasszon csomagot!&nbsp;
                {hasOptionCapacity() && (remainingOptions() > 0 ? 'Foglalható csomagok száma: ' + remainingOptions() : (
                    <b>Elfogyott!</b>))}
            </Typography>
            <Divider variant="inset" component="div" className={classes.divider} />
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                {options.sort((a, b) => a.id - b.id).map(option => option.id ? (
                    <Grid item key={option.id} xs={12} sm={4} md={4}>
                        <Card elevation={3}
                            className={`${classes.option} ${selection[option.id] ? classes.selected : classes.notSelected}`}>
                            <CardHeader
                                title={optionName(option)}
                                subheader={<div className={classes.fontAdjust}>{<div dangerouslySetInnerHTML={{__html: optionDesc(option)}}></div>}</div>}
                            />
                            <CardMedia
                                className={classes.media}
                                image={'./img/' + option.id + '.jpg'}
                            />
                            <CardContent>
                                <Typography align="right">
                                    {numberFormat(option.price)} Ft
                                </Typography>
                            </CardContent>
                            <CardActions disableSpacing>
                                <Grid container alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <Button variant="contained"
                                            color="primary"
                                            disabled={remainingOptions() < 1}
                                            onClick={() => option.id && clickHandler(option.id, 1)}>
                                            <PlusOne />
                                        </Button>
                                    </Grid>
                                    {selection[option.id] ? (
                                        <React.Fragment>
                                            <Grid item>
                                                {selection[option.id]} adag
                                            </Grid>
                                            <Grid item>
                                                <Button variant="contained"
                                                    onClick={() => option.id && clickHandler(option.id, -1)}>
                                                    -
                                                </Button>
                                            </Grid>
                                        </React.Fragment>
                                    ) : ''}
                                </Grid>
                            </CardActions>
                        </Card>
                    </Grid>
                ) : 'null id')}
            </Grid>
            <Divider variant="inset" component="div" className={classes.divider} />
            <Typography>
            * Italfogyasztás az előadás előtt korlátlan mennyiségben
            </Typography>
            <Grid container alignItems="center" justifyContent="center" className={classes.submit}>
                <Grid item>
                    <Button variant="contained"
                        color="primary"
                        onClick={() => onSelection(selection)}>
                        Rendelési adatok megadása
                    </Button>
                </Grid>
            </Grid>
        </Root>
    );
}
