import {NavigateFunction} from "react-router-dom";
import React from "react";

export function apiErrorHandler(setError: (message: string) => void) {
    return (response: Response) => {
        if (response.ok) {
            return response;
        }
        if ([400, 401, 403].includes(response.status)) {
            return Promise.reject(response);
        }
        response.text().then(text => {
            const message = response.status + " " + response.statusText + "\n" + text;
            setError(message);
        });
        throw new Error("Request failed: " + response.statusText);
    }
}

export function queryStringSetter(queryString: URLSearchParams): (upsert: Record<string, string>) => string {
    return upsert => '?' + new URLSearchParams({...Object.fromEntries(queryString.entries()), ...upsert});
}

export function navigator(navigate: NavigateFunction): (to: string, e?: React.FormEvent) => void {
    return (to, e) => {
        navigate(to);
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }
}
