import React, {useContext, useEffect, useState} from "react";
import {Divider, List, ListItem, ListItemAvatar, ListItemText, Typography} from "@mui/material";
import {ApiContext} from "../../Api";
import {Event} from '@praebitor/client';
import {aN} from "../../../utils/assert";
import {DateFormat} from "../../utils/DateFormat";
import {TimeFormat} from "../../utils/TimeFormat";
import {Link, Route, Routes} from "react-router-dom";
import {Areas} from "./Areas";

export function Events() {
    const api = useContext(ApiContext);
    const [events, setEvents] = useState([] as Array<Event>);

    useEffect(() => {
        api.events().then(result => {
            setEvents(result);
        });
    }, [api]);

    return (<Routes>
        <Route
            path="/:eventId/*"
            element={<Areas/>}
        />
        <Route
            path="/"
            element={<React.Fragment>
                <Typography variant={"body1"}>
                    Kérem válassza ki az előadást melyre előrendelni kíván!
                </Typography>
                <List>
                    {events.map((event, idx) => (
                        <React.Fragment key={event.id}>
                            {idx > 0 ? <Divider variant="inset" component="li"/> : ''}
                            <Link to={"" + aN(event.id)}>
                                <ListItem button>
                                    <ListItemAvatar>
                                        <DateFormat date={event.date}/>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={event.name}
                                        secondary={<TimeFormat date={event.date}/>}
                                    />
                                </ListItem>
                            </Link>
                        </React.Fragment>
                    ))}
                </List>
            </React.Fragment>}
        />
    </Routes>);
}
