import { Link, Route, Routes } from "react-router-dom";
import { styled } from '@mui/material/styles';
import { Landing } from "./Landing";
import Order from "./Order";
import Summary from "./Summary";
import Code from "./Code";
import preval from 'preval.macro';
import React, { useContext, useEffect, useRef, useState } from "react";
import { Container, Grid, Typography } from "@mui/material";
import { Contact } from "./Contact";
import { Analytics } from "../analytics/Analytics";
import { ApiContext } from "../Api";

const PREFIX = 'Layout';

const classes = {
    flex: `${PREFIX}-flex`,
    footer: `${PREFIX}-footer`,
    version: `${PREFIX}-version`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    backgroundImage: 'linear-gradient(0, rgba(2,0,36,1) 0%, rgba(0,212,255,0) 100%), url(img/bg-md.jpg), url(img/bg-sx.jpg)',
    backgroundSize: 'cover, cover, cover',
    backgroundAttachment: 'fixed',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    minHeight: '100%',

    [`& .${classes.flex}`]: {
        display: 'flex',
        flex: '1'
    },

    [`& .${classes.footer}`]: {
        color: 'rgba(255, 255, 255, 0.7)',
        borderTop: '1px solid rgba(255, 255, 255, 0.2)',
        position: 'relative'
    },

    [`& .${classes.version}`]: {
        position: 'absolute',
        bottom: 0,
        left: '2px',
        fontFamily: 'monospace',
        fontSize: '9px'
    }
}));

const Content = () => <Routes>
    <Route path="/" element={<Landing />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/order/*" element={<Order />} />
    <Route path="/summary" element={<Summary />} />
    <Route path="/code/:code" element={<Code />} />
</Routes>;

function Version() {

    const [version, setVersion] = useState('...');
    const api = useContext(ApiContext);
    const buildDate = preval`module.exports = new Date().toISOString();`

    useEffect(() => {
        api.getVersion().then(version => setVersion(version));
    }, [api]);

    return <div className={classes.version}>{version}/{buildDate}</div>;
}

export const Layout = () => {

    const analyticsRef = useRef<{ show: () => void }>(null);

    return (
        <Root>
            <Grid item className={classes.flex}>
                <Content />
            </Grid>
            <Grid item>
                <footer className={classes.footer}>
                    <Container maxWidth="md">
                        <Typography variant="body2" align="center">
                            <Link to="/">Broadway Catering Kft.</Link> ♦&nbsp;
                            <Link to="/contact">Kapcsolat</Link> ♦&nbsp;
                            <a href="/legal/aszf.pdf">ÁSZF</a> ♦&nbsp;
                            <span onClick={() => analyticsRef.current?.show()}>Cookie beállítások</span> ♦&nbsp;
                            <a href="/legal/adatkezeles.pdf">Adatkezelési Tájékoztató</a>
                        </Typography>
                    </Container>
                    <Container maxWidth="md">
                        <Typography variant="body2" align="center">
                            <img src="img/barion.png" alt="Barion" />
                        </Typography>
                    </Container>
                    <Version></Version>
                </footer>
            </Grid>
            <Analytics ref={analyticsRef} />
        </Root>
    );
}
