import React from "react";
import { styled } from '@mui/material/styles';
import {Events} from "./steps/Events";
import {Border} from "../fragments/Border";

const PREFIX = 'Order';

const classes = {
    root: `${PREFIX}-root`,
    pane: `${PREFIX}-pane`,
    innerContainer: `${PREFIX}-innerContainer`,
    warning: `${PREFIX}-warning`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {
        flexGrow: 1,
        paddingTop: theme.spacing(2),
    },

    [`& .${classes.pane}`]: {
        paddingBottom: theme.spacing(4),
    },

    [`& .${classes.innerContainer}`]: {
        background: 'rgba(255, 255, 255, 0.4)',
        padding: theme.spacing(2),
        backdropFilter: 'blur(10px)',
    },

    [`& .${classes.warning}`]: {
        position: 'fixed',
        bottom: theme.spacing(5),
        right: theme.spacing(2),
    }
}));

export default function Order() {


    return (
        <Root className={classes.root}>
            <div className={classes.pane}>
                <Border>
                    <Events/>
                </Border>
            </div>
        </Root>
    );
}
