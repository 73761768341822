import { Suspense } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Api } from './components/Api';
import { createTheme, CssBaseline, ThemeProvider, StyledEngineProvider } from '@mui/material';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { Layout } from './components/pages/Layout';
import { CookiesProvider } from 'react-cookie';
import { LocalizationProvider } from '@mui/x-date-pickers';

const theme = createTheme();

function App() {
    return (
        <CookiesProvider>
            <Api>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <CssBaseline />
                            <BrowserRouter>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Routes>
                                        <Route path="/*" element={<Layout />} />
                                    </Routes>
                                </Suspense>
                            </BrowserRouter>
                        </LocalizationProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </Api>
        </CookiesProvider>
    );
}

export default App;
