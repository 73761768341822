import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { getProvider } from './instrumentation';

// setUpTracing();

const reactRoot = createRoot(document.getElementById('root')!); // createRoot(container!) if you use TypeScript
reactRoot.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

// Barion Pixel
declare global {
    interface Window {
        bp: Function & { l?: number, q?: Array<any> },
        barion_pixel_id: string
    }
}

window["bp"] = function () {
    (window["bp"].q = window["bp"].q || []).push(arguments);
};
window["bp"].l = ~~new Date();

const scriptElement = document.createElement("script");
scriptElement.async = true;
scriptElement.src = 'https://pixel.barion.com/bp.js';

document.body.append(scriptElement);
window['barion_pixel_id'] = 'BP-d6vm6B9AoX-36';
// Send init event
window['bp']('init', 'addBarionPixelId', window['barion_pixel_id']);

// getProvider();
