import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Details } from "./Details";
import React, { useContext, useEffect } from "react";
import { Options } from "./Options";
import { AreaWithInfoRestModel, EventOrder, OrderDetailsRestModel } from "@praebitor/client";
import { createPaymentRequest } from "../../services/payment";
import { ApiContext } from "../../Api";
import { Preview } from "./Preview";
import { aN } from "../../../utils/assert";

function Guard(props: { guard: () => boolean, route: string }) {
    const navigate = useNavigate();

    useEffect(() => {
        if (props.guard()) {
            navigate(props.route, { replace: true });
        }
    }, [props, navigate]);

    return <div />;
}

export default function OrderStep({ areas }: { areas: AreaWithInfoRestModel[] }) {
    const [details, setDetails] = React.useState({} as OrderDetailsRestModel);
    const [options, setOptions] = React.useState({} as { [optionId: number]: number });
    const [order, setOrder] = React.useState({} as EventOrder);
    const params = useParams<"eventId" | "areaId">();
    const navigate = useNavigate();
    const api = useContext(ApiContext);

    const areaId = ~~aN(params.areaId);
    const eventId = ~~aN(params.eventId);

    const area = areas.find(area => area.area.id === areaId);

    const optionsSelected = (options: { [optionId: number]: number }) => {
        setOptions(options);
        navigate("details");
    };

    const detailsSubmitted = (details: OrderDetailsRestModel) => {
        setDetails(details);
        const request = createPaymentRequest(areaId, eventId, options, details);
        api.previewOrder(request)
            .then(result => {
                setOrder(result);
                navigate("preview");
            });

    };

    const previewSubmitted = () => {
        const request = createPaymentRequest(areaId, eventId, options, details);
        api.placeOrder(request)
            .then(result => window.location.assign(result));
    };

    return !area ? (<div>Area not found: {areaId}</div>) : <React.Fragment>
        <Routes>
            <Route
                path="/preview"
                element={<Guard guard={andFn(objectEmpty(options), objectEmpty(details))} route=".." />}
            />
            <Route
                path="/details"
                element={<Guard guard={objectEmpty(options)} route=".." />}
            />
        </Routes>
        <Routes>
            <Route
                path="/preview"
                element={<Preview order={order} onSubmit={previewSubmitted} />}
            />
            <Route
                path="/details"
                element={<Details onSubmit={detailsSubmitted} />}
            />
            <Route
                path="/"
                element={<Options area={area} eventId={eventId} onSelection={optionsSelected} />} />
        </Routes>
    </React.Fragment>;
}

const objectEmpty = (object: Object) => () => !object || !Object.keys(object).length;
const andFn = (fn1: () => boolean, fn2: () => boolean) => () => fn1() && fn2();
