import React from "react";
import {dateFormatter} from "../../utils/format";

export function TimeFormat({date}: { date: string }) {
    const getPart = dateFormatter(date, {dateStyle: "full", timeStyle: "full"});

    return <React.Fragment>
        {getPart("year")}. {getPart("month")}. {getPart("day")} {getPart("hour")}:{getPart("minute")}
    </React.Fragment>;
}
