import {Direction, Page, Pageable} from './data-domain';
import {Event} from './entity-event';
import {Option} from './entity-option';
import {EventOrder, Order} from './entity-order';
import {AreaWithInfoRestModel, OptionWithQuantity, OrderDetailsRestModel, OrderRestModel, OrderSummaryRestModel} from './praebitor-model';

export class AdminController {
  additionalParams: () => RequestInit;
  baseURL: URL;
  errorHandler: (error: Response) => Response | Promise<Response>;


 public check(): Promise<Response>  {
const url =  new URL('/api/admin/', this.baseURL);

return fetch(url.toString(), {method: 'GET',...(this?.additionalParams() || {})}).then(this.errorHandler);
  }

 public check2(): Promise<Response>  {
const url =  new URL('/api/admin/test', this.baseURL);

return fetch(url.toString(), {method: 'GET',...(this?.additionalParams() || {})}).then(this.errorHandler);
  }

 public constructor(errorHandler: (error: Response) => Response | Promise<Response>, additionalParams: () => RequestInit, baseURL: URL = new URL(window.document.URL)) {
    this.baseURL = baseURL;
    this.errorHandler = errorHandler;
    this.additionalParams = additionalParams;
  }

 public daySummary(day: any): Promise<OptionWithQuantity[]>  {
const url =  new URL('/api/admin/day-summary', this.baseURL);
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'day', value: day.toString()});
  
    for (const queryParam of queryParamsList) {
url.searchParams.append(queryParam.name,queryParam.value);
    }

return fetch(url.toString(), {method: 'GET',...(this?.additionalParams() || {})}).then(this.errorHandler).then(res => res.text()).then(res => JSON.parse(res));
  }

 public download(): Promise<Response>  {
const url =  new URL('/api/admin/download', this.baseURL);

return fetch(url.toString(), {method: 'GET',...(this?.additionalParams() || {})}).then(this.errorHandler);
  }

 public getOrders(): Promise<EventOrder[]>  {
const url =  new URL('/api/admin/orders', this.baseURL);

return fetch(url.toString(), {method: 'GET',...(this?.additionalParams() || {})}).then(this.errorHandler).then(res => res.text()).then(res => JSON.parse(res));
  }

 public getOrdersFiltered(day: any, code: string, page: Pageable): Promise<Page<EventOrder> >  {
const url =  new URL('/api/admin/orders-filtered', this.baseURL);
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'day', value: day.toString()});
  
    queryParamsList.push({name: 'code', value: code});
  
if(page) {
queryParamsList.push({name: 'page', value: page.pageNumber+'' });
queryParamsList.push({name: 'size', value: page.pageSize+'' });
if(page.sort && page.sort.sortOrders) {
for(const sortOrder of page.sort.sortOrders) {
queryParamsList.push({name: 'sort', value: sortOrder.property + (sortOrder.direction === Direction.DESC ? ',DESC' : '')  });

}
}
}
    for (const queryParam of queryParamsList) {
url.searchParams.append(queryParam.name,queryParam.value);
    }

return fetch(url.toString(), {method: 'GET',...(this?.additionalParams() || {})}).then(this.errorHandler).then(res => res.text()).then(res => JSON.parse(res));
  }

 public testSmtp(): Promise<string>  {
const url =  new URL('/api/admin/test/smtp', this.baseURL);

return fetch(url.toString(), {method: 'GET',...(this?.additionalParams() || {})}).then(this.errorHandler).then(res =>  res.text());
  }

 public uploadSheet(file: any): Promise<string>  {
const url =  new URL('/api/admin/upload', this.baseURL);
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'file', value: file.toString()});
  
    for (const queryParam of queryParamsList) {
url.searchParams.append(queryParam.name,queryParam.value);
    }

return fetch(url.toString(), {method: 'POST',...(this?.additionalParams() || {})}).then(this.errorHandler).then(res =>  res.text());
  }

}

export class ApiController {
  additionalParams: () => RequestInit;
  baseURL: URL;
  errorHandler: (error: Response) => Response | Promise<Response>;


 public areas(eventId: number): Promise<AreaWithInfoRestModel[]>  {
const url =  new URL('/api/areas/' + eventId + '', this.baseURL);

return fetch(url.toString(), {method: 'GET',...(this?.additionalParams() || {})}).then(this.errorHandler).then(res => res.text()).then(res => JSON.parse(res));
  }

 public constructor(errorHandler: (error: Response) => Response | Promise<Response>, additionalParams: () => RequestInit, baseURL: URL = new URL(window.document.URL)) {
    this.baseURL = baseURL;
    this.errorHandler = errorHandler;
    this.additionalParams = additionalParams;
  }

 public events(): Promise<Event[]>  {
const url =  new URL('/api/events', this.baseURL);

return fetch(url.toString(), {method: 'GET',...(this?.additionalParams() || {})}).then(this.errorHandler).then(res => res.text()).then(res => JSON.parse(res));
  }

 public getEventOrderByCode(code: string): Promise<EventOrder>  {
const url =  new URL('/api/code/' + code + '', this.baseURL);

return fetch(url.toString(), {method: 'GET',...(this?.additionalParams() || {})}).then(this.errorHandler).then(res => res.text()).then(res => JSON.parse(res));
  }

 public getOrder(paymentId: string): Promise<OrderSummaryRestModel>  {
const url =  new URL('/api/order', this.baseURL);
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'paymentId', value: paymentId});
  
    for (const queryParam of queryParamsList) {
url.searchParams.append(queryParam.name,queryParam.value);
    }

return fetch(url.toString(), {method: 'GET',...(this?.additionalParams() || {})}).then(this.errorHandler).then(res => res.text()).then(res => JSON.parse(res));
  }

 public getVersion(): Promise<string>  {
const url =  new URL('/api/version', this.baseURL);

return fetch(url.toString(), {method: 'GET',...(this?.additionalParams() || {})}).then(this.errorHandler).then(res =>  res.text());
  }

 public options(areaId: number): Promise<Option[]>  {
const url =  new URL('/api/options/' + areaId + '', this.baseURL);

return fetch(url.toString(), {method: 'GET',...(this?.additionalParams() || {})}).then(this.errorHandler).then(res => res.text()).then(res => JSON.parse(res));
  }

 public placeOrder(orderRestModel: OrderRestModel): Promise<string>  {
const url =  new URL('/api/order', this.baseURL);

return fetch(url.toString(), {method: 'POST',headers: {'Content-Type': 'application/json'}, body: JSON.stringify(orderRestModel), ...(this?.additionalParams() || {})}).then(this.errorHandler).then(res =>  res.text());
  }

 public previewOrder(orderRestModel: OrderRestModel): Promise<EventOrder>  {
const url =  new URL('/api/preview-order', this.baseURL);

return fetch(url.toString(), {method: 'POST',headers: {'Content-Type': 'application/json'}, body: JSON.stringify(orderRestModel), ...(this?.additionalParams() || {})}).then(this.errorHandler).then(res => res.text()).then(res => JSON.parse(res));
  }

 public validateOrder(model: OrderDetailsRestModel): Promise<OrderDetailsRestModel>  {
const url =  new URL('/api/validate', this.baseURL);

return fetch(url.toString(), {method: 'POST',headers: {'Content-Type': 'application/json'}, body: JSON.stringify(model), ...(this?.additionalParams() || {})}).then(this.errorHandler).then(res => res.text()).then(res => JSON.parse(res));
  }

}

export class BarionController {
  additionalParams: () => RequestInit;
  baseURL: URL;
  errorHandler: (error: Response) => Response | Promise<Response>;


 public callback(paymentId: string): Promise<Response>  {
const url =  new URL('/api/barion/callback', this.baseURL);
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'paymentId', value: paymentId});
  
    for (const queryParam of queryParamsList) {
url.searchParams.append(queryParam.name,queryParam.value);
    }

return fetch(url.toString(), {method: 'POST',...(this?.additionalParams() || {})}).then(this.errorHandler);
  }

 public constructor(errorHandler: (error: Response) => Response | Promise<Response>, additionalParams: () => RequestInit, baseURL: URL = new URL(window.document.URL)) {
    this.baseURL = baseURL;
    this.errorHandler = errorHandler;
    this.additionalParams = additionalParams;
  }

 public redirect(paymentId: string): Promise<Order>  {
const url =  new URL('/api/barion/redirect', this.baseURL);
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'paymentId', value: paymentId});
  
    for (const queryParam of queryParamsList) {
url.searchParams.append(queryParam.name,queryParam.value);
    }

return fetch(url.toString(), {method: 'GET',...(this?.additionalParams() || {})}).then(this.errorHandler).then(res => res.text()).then(res => JSON.parse(res));
  }

}

