import {BusinessClient, Client, PersonClient} from "@praebitor/client";

export function isPersonClient(client: Client): client is PersonClient {
    return client.type === 'person';
}

export function isBusinessClient(client: Client): client is BusinessClient {
    return client.type === 'business';
}

export function isAnyClient(client: Client): client is BusinessClient | PersonClient {
    if (isPersonClient(client)) return true;
    return isBusinessClient(client);
}
