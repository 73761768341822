export function dateFormatter(date: string, options: Intl.DateTimeFormatOptions): (type: Intl.DateTimeFormatPartTypes) => string {
    const parts = new Intl.DateTimeFormat('hu-HU', options)
        .formatToParts(new Date(date));

    return function getPart(type: Intl.DateTimeFormatPartTypes) {
        const result = parts.find(x => x.type === type);
        if (!result) throw new Error(`Part ${type} not found`);
        return result.value;
    }
}

export function numberFormat(input: number): string {
    return new Intl.NumberFormat('hu').format(input);
}
